import React from 'react'
import Breadcrum from '../../components/breadcrum/Breadcrum'
import Banner from '../banner/Banner'
import Footer from '../common/Footer'
import Header from '../common/Header'
import { useGetSetting } from '../../shared/hooks/UseSetting'
import renderHtml from "react-render-html";



const RefundPolicy = () => {

  const [setting_data] = useGetSetting()
  const { settings, loading } = setting_data;

  return (
    <div>
      <Banner
        Banner_title={"Refund Policy"}
        Banner_desktop_img={"assets/images/210310-11-063.jpg"}
        Banner_mobile_img={"assets/images/210310-11-063_mobile_banner.jpg"}
      />
      <div className="privacy-section mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="privacy-policy-wrapper">
                <div
                  className="privacy-single-item aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay={0}
                >
                  <div className="privacy-policy-content">
                    <div className="privacy-policy-header">
                      <h3>Refund Policy</h3>
                    </div>
                    <div className="privacy-policy-paragraph">
                      {settings && settings.refund_policy && renderHtml(settings.refund_policy)}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default RefundPolicy;
