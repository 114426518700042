import React, { useState } from 'react'
import Banner from '../banner/Banner'
import Footer from '../common/Footer'
import { useSelectAllPresswork } from "../../shared/hooks/UsePresswork"
import { URI } from '../../domain/constant';
import renderHTML from 'react-render-html'
import moment from 'moment'
import PressworkCard from './PressworkCard';

function Press() {

    const [presswork_data] = useSelectAllPresswork();
    const { all_pressworks } = presswork_data;

    const [isImageShowing, setIsImageShowing] = useState(false)
    const [imgPath, setImgPath] = useState(null)

    return (
        <div>
            <Banner
                Banner_title={"Press"}
                Banner_desktop_img={"assets/images/210310-5-048 1.jpg"}
                Banner_mobile_img={"assets/images/210310-5-0481_mobile_banner.jpg"}
                is_asha_page={true}
            />
            <section>
                <div className="container">
                    <div className="row">
                        {all_pressworks && all_pressworks.map((item) => {
                            return (
                                <div className="col-md-4 mx-auto">
                                    <div className="press ptb-50">
                                        <div className="card " style={{ width: '18rem' }}>
                                            <img className="card-img-top press-card-img" src={`${URI}${item.image}`} />
                                            <div className="card-body">
                                                <h5 className="card-title">{item.title}</h5>
                                                {
                                                    // <p className="card-text">{presswork_description}</p>
                                                }
                                                <a href="#" onClick={() => { setIsImageShowing(true); setImgPath(`${URI}${item.image}`) }} className="btn btn-primary">Know More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            {isImageShowing &&
                <div onClick={() => { setIsImageShowing(false); setImgPath(null) }} style={{ position: 'fixed', top: 0, left: 0, height: '100%', width: '100%', background: 'rgba(0, 0, 0, 0.8)', zIndex: 9999 }}>
                    <img src={imgPath} alt="national ngo org" style={{ width: '100%', height: '100vh', objectFit: 'contain' }} />
                </div>
            }
            <Footer />
        </div>
    )
}

export default Press